import { useAuthorizationStore } from '@/stores/useAuthStore.js'

const authRoutes = [
	{
		name: 'auth.login',
		path: '/login', // ?token=abc redirects to /login-token?token=abc to keep the old mail setup
		meta: {
			layout: 'auth',
			requiresLogout: true
		},
		component: () => import('@/modules/auth/pages/LoginPage.vue'),
		beforeEnter: (to, from, next) => {
			const auth = useAuthorizationStore()
			if (auth.isSessionActive()) {
				next({ name: 'app.start' })
			} else {
				next()
			}
		}
	},
	{
		name: 'auth.logout', // logs out and redirects to auth.login
		path: '/logout',
		meta: {
			layout: 'none'
		},
		component: () => import('@/modules/auth/pages/LogoutPage.vue')
	},
	{
		name: 'auth.register',
		path: '/register/:id([a-zA-Z0-9]+)',
		meta: {
			layout: 'auth',
			requiresLogout: true
		},
		component: () => import('@/modules/auth/pages/RegisterPage.vue')
	},
	{
		name: 'auth.forgot',
		path: '/forgot',
		meta: {
			layout: 'auth',
			requiresLogout: true
		},
		component: () => import('@/modules/auth/pages/ForgotPassword.vue')
	},
	{
		name: 'auth.newPassword',
		path: '/password/new',
		meta: {
			layout: 'auth',
			requiresLogout: true
		},
		component: () => import('@/modules/auth/pages/ChangePassword.vue')
	}
]

export default authRoutes
