import Logger from '@/helpers/logging/Logger.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { supportedLanguages } from '@/constants/supportedLanguages.js'

/**
 * @returns {string|boolean} the language in the URL
 */
function languageFromQueryString() {
	const urlParams = new URLSearchParams(window.location.search)
	let queryLanguage = false
	if (urlParams.has('lang') && supportedLanguages.includes(urlParams.get('lang'))) {
		queryLanguage = urlParams.get('lang')
	}
	return queryLanguage
}

/**
 * Check the current language from the browser, query, stored or system language with a fallback to 'en'
 * @returns {string} the language string
 */
function getCurrentLanguage() {
	Logger.debug('getCurrentLanguage')

	const auth = useAuthorizationStore()
	if (auth.isSessionActive()) {
		const systemLanguage = auth.user?.system_language_key
		if (systemLanguage !== undefined) {
			Logger.debug('using user system language', systemLanguage)
			return systemLanguage
		}
	}

	const storedLanguage = localStorage.language
	if (storedLanguage !== null && supportedLanguages.includes(storedLanguage)) {
		Logger.debug('using stored language', storedLanguage)
		return storedLanguage
	}

	const queryLanguage = languageFromQueryString()
	if (queryLanguage && supportedLanguages.includes(queryLanguage)) {
		Logger.debug('using language from query string', queryLanguage)
		return queryLanguage
	}

	const browserLanguage = window.navigator.language
	if (browserLanguage !== null) {
		const localeParts = browserLanguage.split('-')
		Logger.debug('localeParts', localeParts)

		const browserLanguagePart = localeParts[0].toLowerCase()
		if (supportedLanguages.includes(browserLanguagePart)) {
			Logger.debug('using browser language', browserLanguagePart)
			return browserLanguagePart
		}
	}

	Logger.debug('fallback language en')
	return 'en'
}

export default getCurrentLanguage
