import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import handleApiError from '@/helpers/logging/handleApiError.js'
import Logger from '@/helpers/logging/Logger.js'
import { computed, ref } from 'vue'
import { defaultExpiresTime, errorRetryTime, nowTime } from '@/stores/storeDefaults.js'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useFeaturesStore = defineStore('features', () => {
	const features = useStorage('features', [], sessionStorage)
	const loading = ref(false)
	const expires = useStorage('features-expires', null, sessionStorage)

	function isActive(featureName) {
		fetchFeatures()
		const featureExists = features.value.find(feature => feature.name === featureName)
		return featureExists?.active === true
	}

	const list = computed(() => {
		fetchFeatures()
		return features.value
	})

	/**
	 * Fetch features
	 * @param {boolean} [force]
	 * @returns {Promise} A promise to act upon
	 */
	async function fetchFeatures(force = false) {
		if (force) { loading.value = false }
		if (loading.value) { return }
		if (new Date().getTime() < expires.value && !force) { return }
		// const auth = useAuthorizationStore()
		// if (!auth.accessToken) { return }

		loading.value = true
		await axiosHttpClient
			.get('featuretoggles')
			.then(response => {
				features.value = response?.data ?? []
				expires.value = nowTime() + defaultExpiresTime
			})
			.catch(e => {
				Logger.debug('useFeaturesStore: fetch failed')
				expires.value = nowTime() + errorRetryTime
				handleApiError(e)
			})
			.finally(() => { loading.value = false })
	}

	/**
	 * Clean up the data
	 */
	function remove() {
		loading.value = false
		features.value = []
		expires.value = 0
	}

	return {
		features,
		loading,
		expires,
		isActive,
		list,
		fetch: fetchFeatures,
		remove
	}
})
