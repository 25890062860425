const suppliersRoutes = {
	path: '/suppliers',
	name: 'module.suppliers',
	redirect: { name: 'suppliers.locations.index' },
	meta: {
		requiresAuth: true,
		permissions: [ 'suppliers' ]
	},
	component: () => import('@/modules/suppliers/SuppliersModule.vue'),
	children: [
		{
			name: 'suppliers.relations',
			path: 'relations',
			children: [
				{
					name: 'suppliers.relations.index',
					path: '',
					meta: {
						permissions: [ 'suppliers' ],
					},
					component: () => import('@/modules/suppliers/pages/RelationsIndex.vue')
				},
				{
					name: 'suppliers.relations.create',
					path: 'create',
					meta: {
						permissions: [ 'suppliers' ],
					},
					props: { isNew: true, isEdit: true },
					component: () => import('@/modules/suppliers/pages/RelationPage.vue')
				},
				{
					name: 'suppliers.relations.show',
					path: ':relationId(\\d+)',
					meta: {
						permissions: [ 'suppliers' ],
					},
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/suppliers/pages/RelationPage.vue')
				},
				{
					name: 'suppliers.relations.edit',
					path: ':relationId(\\d+)/edit',
					meta: {
						permissions: [ 'suppliers' ],
					},
					props: { isNew: false, isEdit: true },
					component: () => import('@/modules/suppliers/pages/RelationPage.vue')
				}
			]
		},
		{
			name: 'suppliers.locations',
			path: 'locations',
			redirect: { name: 'suppliers.locations.index' },
			children: [
				{
					name: 'suppliers.locations.index',
					path: '',
					meta: {
						permissions: [ 'suppliers' ],
					},
					component: () => import('@/modules/suppliers/pages/LocationsIndex.vue')
				},
				{
					name: 'suppliers.locations.create',
					path: 'create',
					component: () => import('@/modules/suppliers/pages/LocationPage.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					name: 'suppliers.locations.show',
					path: ':locationId(\\d+)',
					component: () => import('@/modules/suppliers/pages/LocationPage.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					name: 'suppliers.locations.edit',
					path: ':locationId(\\d+)/edit',
					component: () => import('@/modules/suppliers/pages/LocationPage.vue'),
					props: { isNew: false, isEdit: true }
				},
				{
					name: 'suppliers.locations.products.create',
					path: ':locationId(\\d+)/products/create',
					component: () => import('@/modules/suppliers/pages/LocationProductPage.vue'),
					props: { isNew: true, isEdit: true, }
				},
				{
					name: 'suppliers.locations.products.edit',
					path: ':locationId(\\d+)/products/:productId(\\d+)/edit',
					component: () => import('@/modules/suppliers/pages/LocationProductPage.vue'),
					props: { isNew: false, isEdit: true, }
				},
				{
					name: 'suppliers.locations.products.show',
					path: ':locationId(\\d+)/products/:productId(\\d+)/',
					component: () => import('@/modules/suppliers/pages/LocationProductPage.vue'),
					props: { isNew: false, isEdit: false, },
				},
				{
					name: 'suppliers.locations.additionalfiles.edit',
					path: ':locationId(\\d+)/additionalfiles/:additionalFilesId(\\d+)/edit',
					component: () => import('@/modules/suppliers/pages/AdditionalFilePage.vue'),
					props: { isNew: false, isEdit: true, }
				},
				{
					name: 'suppliers.locations.additionalfiles.show',
					path: ':locationId(\\d+)/additionalfiles/:additionalFilesId(\\d+)',
					component: () => import('@/modules/suppliers/pages/AdditionalFilePage.vue'),
					props: { isNew: false, isEdit: false, }
				},
				{
					name: 'suppliers.locations.products.specifications.create',
					path: ':locationId(\\d+)/products/:productId(\\d+)/specifications/create',
					component: () => import('@/modules/suppliers/pages/SpecificationPage.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					name: 'suppliers.locations.products.specifications.show',
					path: ':locationId(\\d+)/products/:productId(\\d+)/specifications/:specificationId(\\d+)',
					component: () => import('@/modules/suppliers/pages/SpecificationPage.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					name: 'suppliers.locations.products.specifications.edit',
					path: ':locationId(\\d+)/products/:productId(\\d+)/specifications/:specificationId(\\d+)/edit',
					component: () => import('@/modules/suppliers/pages/SpecificationPage.vue'),
					props: { isNew: false, isEdit: true }
				},
				{
					name: 'suppliers.locations.certificates.create',
					path: ':locationId(\\d+)/certificates/create',
					component: () => import('@/modules/suppliers/pages/LocationCertificatePage.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					name: 'suppliers.locations.certificates.edit',
					path: ':locationId(\\d+)/certificates/:certificateId(\\d+)/edit',
					component: () => import('@/modules/suppliers/pages/LocationCertificatePage.vue'),
					props: { isNew: false, isEdit: true }
				},
				{
					name: 'suppliers.locations.certificates.show',
					path: ':locationId(\\d+)/certificates/:certificateId(\\d+)',
					component: () => import('@/modules/suppliers/pages/LocationCertificatePage.vue'),
					props: { isNew: false, isEdit: false }
				},
				{
					name: 'suppliers.locations.supplieragreements.create',
					path: ':locationId(\\d+)/supplieragreement/create',
					component: () => import('@/modules/suppliers/pages/AgreementPage.vue'),
					props: { isNew: true, isEdit: true }
				},
				{
					name: 'suppliers.locations.supplieragreements.edit',
					path: ':locationId(\\d+)/supplieragreement/:supplierAgreementId(\\d+)/edit',
					component: () => import('@/modules/suppliers/pages/AgreementPage.vue'),
					props: { isNew: false, isEdit: true },
				},
				{
					name: 'suppliers.locations.supplieragreements.show',
					path: ':locationId(\\d+)/supplieragreement/:supplierAgreementId(\\d+)',
					component: () => import('@/modules/suppliers/pages/AgreementPage.vue'),
					props: { isNew: false, isEdit: false },
				},
				{
					name: 'suppliers.locations.questionnaires.show',
					path: ':locationId(\\d+)/questionnaire/:questionnaireId(\\d+)',
					component: () => import('@/modules/suppliers/pages/NewLocationQuestionnaire.vue')
				},
				{
					name: 'suppliers.locations.questionnaires.questions.show',
					path: ':locationId(\\d+)/questionnaire/:questionnaireVersionId(\\d+)/question/:questionId(\\d+)',
					component: () => import('@/modules/suppliers/pages/QuestionShow.vue')
				}
			]
		},
		{
			name: 'suppliers.products',
			path: 'products',
			redirect: { name: 'suppliers.products.index' },
			children: [
				{
					name: 'suppliers.products.index',
					path: '',
					component: () => import('@/modules/suppliers/pages/ProductsIndex.vue')
				},
				{
					name: 'suppliers.products.create',
					path: 'create',
					component: () => import('@/modules/suppliers/pages/ProductsCreate.vue'),
					meta: { permissions: [ 'suppliers.relations.products.create' ] }
				}
			]
		},
		{
			name: 'suppliers.questions',
			path: 'questions',
			redirect: { name: 'suppliers.questions.index' },
			meta: { permissions: [ 'suppliers.questionnaires.questions' ] },
			children: [
				{
					name: 'suppliers.questions.index',
					path: '',
					component: () => import('@/modules/suppliers/pages/QuestionsIndex.vue'),
					meta: { permissions: [ 'suppliers.questionnaires.questions' ] }
				},
				{
					name: 'suppliers.questions.create',
					path: 'create',
					props: { isNew: true, isEdit: true },
					component: () => import('@/modules/suppliers/pages/QuestionPage.vue'),
					meta: { permissions: [ 'suppliers.questionnaires.questions.create' ] }
				},
				{
					name: 'suppliers.questions.show',
					path: ':questionId(\\d+)',
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/suppliers/pages/QuestionPage.vue'),
					meta: { permissions: [ 'suppliers.questionnaires.questions.show' ] }
				},
				{
					name: 'suppliers.questions.edit',
					path: ':questionId(\\d+)/edit',
					props: { isNew: false, isEdit: true },
					component: () => import('@/modules/suppliers/pages/QuestionPage.vue'),
					meta: { permissions: [ 'suppliers.questionnaires.questions.update' ] }
				},
				{
					name: 'suppliers.questions.translations.show',
					path: 'questions/:questionId(\\d+)/translations/:translationId(\\d+)',
					component: () => import('@/modules/suppliers/pages/QuestionTranslationShow.vue')
				},
				{
					name: 'suppliers.questions.translations.edit',
					path: 'questions/:questionId(\\d+)/translations/:translationId(\\d+)/edit',
					component: () => import('@/modules/suppliers/pages/QuestionTranslationEdit.vue')
				},
				{
					name: 'suppliers.questions.versions.show',
					path: 'questions/:questionId(\\d+)/versions/:versionId(\\d+)',
					component: () => import('@/modules/suppliers/pages/QuestionVersionShow.vue')
				}
			]
		},
		{
			name: 'suppliers.questionnaires',
			path: 'questionnaires',
			redirect: { name: 'suppliers.questionnaires.index' },
			meta: { permissions: [ 'suppliers.questionnaires.questionnaires' ] },
			children: [
				{
					name: 'suppliers.questionnaires.index',
					path: '',
					component: () => import('@/modules/suppliers/pages/QuestionnairesIndex.vue'),
					meta: { permissions: [ 'suppliers.questionnaires.questionnaires' ] }
				},
				{
					name: 'suppliers.questionnaires.create',
					path: 'create',
					component: () => import('@/modules/suppliers/pages/QuestionnairePage.vue'),
					props: { isNew: true, isEdit: true },
					meta: { permissions: [ 'suppliers.questionnaires.questionnaires.create' ] }
				},
				{
					name: 'suppliers.questionnaires.show',
					path: ':questionnaireId(\\d+)',
					props: { isNew: false, isEdit: false },
					component: () => import('@/modules/suppliers/pages/QuestionnairePage.vue'),
					meta: { permissions: [ 'suppliers.questionnaires.questionnaires.show' ] }
				},
				{
					name: 'suppliers.questionnaires.edit',
					path: ':questionnaireId(\\d+)/edit',
					props: { isNew: false, isEdit: true },
					component: () => import('@/modules/suppliers/pages/QuestionnairePage.vue'),
					meta: { permissions: [ 'suppliers.questionnaires.questionnaires.update' ] }
				},
				{
					name: 'suppliers.questionnaires.versions.show',
					path: ':questionnaireId(\\d+)/versions/:versionId(\\d+)',
					component: () => import('@/modules/suppliers/pages/QuestionnaireVersionShow.vue')
				}
			]
		},
		{
			name: 'suppliers.controlMeasures',
			path: 'control-measures',
			component: () => import('@/modules/suppliers/pages/ControlMeasuresIndex.vue')
		}
	]
}
export default suppliersRoutes
