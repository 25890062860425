import router from '@/router/routes.js'

/**
 * Turn a vue-router route into a cleaned route
 * @param {any} route the route to get cleaned
 * @returns {*} the cleaned route
 */
const getRoute = (route) => {
	let newRoute = route
	if (typeof (newRoute) === 'string') {
		newRoute = { name: newRoute }
	}
	return router.resolve(newRoute).route
}

/**
 * @param {import('vue-router').NavigationGuardNext} next - The next() function from Vue-Router
 * @param {string} url - The URL to check
 * @returns {void} - A next route
 */
function redirectToLogin(next, url) {
	const routesToFilterOut = [ '/', '/login', '/404', '/403' ]
	if (routesToFilterOut.includes(url)) {
		return next({ path: '/login' }) // Redirect to /login
	} else {
		return next({ path: '/login', query: { r: url } }) // Redirect to /login with a redirect parameter
	}
}

export { getRoute, redirectToLogin }
