import adminRoutes from '@/modules/admin/routes.admin.js'
import auditsRoutes from '@/modules/audits/routes.audits.js'
import authRoutes from '@/modules/auth/routes.auth.js'
import baseRoutes from '@/router/routes.base.js'
import elearningRoutes from '@/modules/elearning/routes.elearning.js'
import innovativeFreshRoutes from '@/modules/innovative-fresh/routes.innovative-fresh.js'
import labRoutes from '@/modules/lab/routes.lab.js'
import Logger from '@/helpers/logging/Logger.js'
import manualsRoutes from '@/modules/manuals/routes.manuals.js'
import registrationRoutes from '@/modules/registrations/routes.registrations.js'
import sensoryRoutes from '@/modules/sensory/routes.sensory.js'
import settingsRoutes from '@/modules/settings/routes.settings.js'
import suppliersIndexRoutes from '@/modules/suppliers-index/routes.supplier-index.js'
import suppliersRoutes from '@/modules/suppliers/routes.suppliers.js'
import userRoutes from '@/modules/user/routes.user.js'
import { createRouter, createWebHistory } from 'vue-router'
import { redirectToLogin } from '@/helpers/routeHelper.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { usePermissionsStore } from '@/stores/usePermissionsStore.js'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		...baseRoutes,
		...authRoutes,
		suppliersRoutes,
		settingsRoutes,
		labRoutes,
		manualsRoutes,
		registrationRoutes,
		...elearningRoutes,
		sensoryRoutes,
		adminRoutes,
		auditsRoutes,
		userRoutes,
		suppliersIndexRoutes,
		innovativeFreshRoutes,
	]
})

router.beforeEach(async (to, from, next) => {
	const auth = useAuthorizationStore()
	Logger.debug(`router.beforeEach: check route ${to.path}`)

	// mark the start of calculations
	performance.mark('routing-start')

	if (to.matched.some(record => record.meta.requiresAuth)) {
		Logger.debug('router.beforeEach: requiresAuth')
		// There is no active session
		if (!auth.isSessionActive()) {
			Logger.debug('no active session')
			await auth.logout() // Logout the user and remove all session info
			const url = to.path // Get URL
			performance.mark('routing-end')
			performance.measure('routing', 'routing-start', 'routing-end')
			return redirectToLogin(next, url)
		}

		// A token was found. Check if we can still refresh it
		const now = Date.now() / 1000 // get seconds instead of ms
		const sessionExpires = auth.expires // when the token expires
		const refreshFrom = sessionExpires - auth.refreshTime // time from when it expires

		// Session is *almost* over.
		if (now < sessionExpires && now > refreshFrom) {
			Logger.debug('router.beforeEach: Session is about to expire. trying to refresh')
			// Try to refresh
			await auth.refresh()
		}
	}

	if (to.matched.some(record => record.meta.requiresLogout)) {
		Logger.debug('router.beforeEach: requiresLogout')
		if (auth.isSessionActive()) {
			performance.mark('routing-end')
			performance.measure('routing', 'routing-start', 'routing-end')
			return next({ path: '/' }) // redirect to home page
		}
	}

	if (to.matched.some(record => record.meta.permissions)) {
		Logger.debug('router.beforeEach: permissions')
		if (!auth.isSessionActive()) {
			await auth.logout() // Logout the user and remove all session info
			const url = to.path // Get URL
			performance.mark('routing-end')
			performance.measure('routing', 'routing-start', 'routing-end')
			return redirectToLogin(next, url)
		}

		const permissions = usePermissionsStore()
		await permissions.fetch()
		if (!permissions.has(...to.meta.permissions)) {
			Logger.debug('router.beforeEach: missing permissions', to.meta.permissions)
			performance.mark('routing-end')
			performance.measure('routing', 'routing-start', 'routing-end')
			return next({ name: '403' })
		}
	}

	if (!to.matched.length) {
		Logger.debug('route notFound')
		performance.mark('routing-end')
		performance.measure('routing', 'routing-start', 'routing-end')
		return next({ name: '404' })
	}

	performance.mark('routing-end')
	performance.measure('routing', 'routing-start', 'routing-end')
	return next()
})

export default router
