const baseRoutes = [
	{
		name: 'app.start',
		path: '/',
		redirect: { name: 'Dashboard' },
		meta: { layout: 'auth' }
	},
	{
		name: 'app.home',
		meta: { requiresAuth: true },
		redirect: { name: 'Dashboard' },
		component: () => import('@/modules/home/HomeModule.vue'),
		children: [
			{
				name: 'Dashboard',
				path: '/',
				component: () => import('@/modules/home/pages/DashboardPage.vue')
			}
		]
	},
	{
		path: '/company',
		name: 'company',
		redirect: { name: 'companyStructure' },
		meta: { requiresAuth: true },
		component: () => import('@/modules/companyStructure/CompanyStructures.vue'),
		children: [
			{
				path: 'structure',
				name: 'companyStructure',
				component: () => import('@/modules/companyStructure/pages/StructurePage.vue')
			},
			{
				path: 'divisions',
				name: 'divisions',
				redirect: { name: 'divisions.index' },
				meta: { permissions: [ 'settings.divisions.divisions' ] },
				children: [
					{
						path: '',
						name: 'divisions.index',
						component: () => import('@/modules/companyStructure/pages/DivisionIndex.vue'),
						meta: { permissions: [ 'settings.divisions.divisions' ] },
					},
					{
						path: 'create',
						name: 'divisions.create',
						component: () => import('@/modules/companyStructure/pages/DivisionPage.vue'),
						props: { isNew: true, isEdit: true },
						meta: { permissions: [ 'settings.divisions.divisions.create' ] },
					},
					{
						path: ':divisionId(\\d+)',
						name: 'divisions.show',
						component: () => import('@/modules/companyStructure/pages/DivisionPage.vue'),
						props: { isNew: false, isEdit: false },
						meta: { permissions: [ 'settings.divisions.divisions.show' ] },
					},
					{
						path: ':divisionId(\\d+)/edit',
						name: 'divisions.edit',
						component: () => import('@/modules/companyStructure/pages/DivisionPage.vue'),
						props: { isNew: false, isEdit: true },
						meta: { permissions: [ 'settings.divisions.divisions.update' ] },
					}
				]
			},
			{
				path: 'departments',
				name: 'departments',
				redirect: { name:'departments.index' },
				meta: { permissions: [ 'settings.departments' ] },
				children: [
					{
						path: '',
						name: 'departments.index',
						component: () => import('@/modules/companyStructure/pages/DepartmentIndex.vue'),
						meta: { permissions: [ 'settings.departments.departments' ] },
					},
					{
						path: 'create',
						name: 'departments.create',
						component: () => import('@/modules/companyStructure/pages/DepartmentPage.vue'),
						meta: { permissions: [ 'settings.departments.departments.create' ] },
						props: { isNew: true, isEdit: true }
					},
					{
						path: ':departmentId',
						name: 'departments.show',
						component: () => import('@/modules/companyStructure/pages/DepartmentPage.vue'),
						meta: { permissions: [ 'settings.departments.departments.show' ] },
						props: { isNew: false, isEdit: false }
					},
					{
						path: ':departmentId/edit',
						name: 'departments.edit',
						component: () => import('@/modules/companyStructure/pages/DepartmentPage.vue'),
						meta: { permissions: [ 'settings.departments.departments.update' ] },
						props: { isNew: false, isEdit: true }
					}
				]
			},
			{
				path: 'locations',
				name: 'locations',
				redirect: { name: 'locations.index' },
				meta: { permissions: [ 'settings.locations' ] },
				children: [
					{
						path: '',
						name: 'locations.index',
						component: () => import('@/modules/companyStructure/pages/LocationIndex.vue'),
						meta: { permissions: [ 'settings.locations.locations' ] },
					},
					{
						path: 'create',
						name: 'locations.create',
						component: () => import('@/modules/companyStructure/pages/LocationPage.vue'),
						meta: { permissions: [ 'settings.locations.locations.create' ] },
						props: { isNew: true, isEdit: true }
					},
					{
						path: ':locationId',
						name: 'locations.show',
						component: () => import('@/modules/companyStructure/pages/LocationPage.vue'),
						meta: { permissions: [ 'settings.locations.locations.show' ] },
						props: { isNew: false, isEdit: false }
					},
					{
						path: ':locationId/edit',
						name: 'locations.edit',
						component: () => import('@/modules/companyStructure/pages/LocationPage.vue'),
						meta: { permissions: [ 'settings.locations.locations.update' ] },
						props: { isEdit: true, isNew: false }
					},
				]
			},
			{
				name: 'users',
				path: 'users',
				redirect: { name: 'users.index' },
				children: [
					{
						name: 'users.index',
						path: '',
						component: () => import('@/modules/companyStructure/pages/EmployeeIndex.vue')
					},
					{
						path: 'create',
						name: 'users.create',
						component: () => import('@/modules/companyStructure/components/Employees/EmployeeDetails.vue'),
						props: { isNew: true, isEdit: true }
					},
					{
						path: ':userId(\\d+)',
						name: 'users.show',
						component: () => import('@/modules/companyStructure/components/Employees/EmployeeDetails.vue'),
						props: { isNew: false, isEdit: false }
					},
					{
						path: ':userId(\\d+)/edit',
						name: 'users.edit',
						component: () => import('@/modules/companyStructure/components/Employees/EmployeeDetails.vue'),
						props: { isNew: false, isEdit: true }
					},
				]
			},
		]
	},
	{
		name: '403',
		path: '/403',
		meta: { requiresAuth: true },
		component: () => import('@/modules/base/pages/NotAuthorized.vue')
	},
	{
		name: '404',
		path: '/404',
		meta: { requiresAuth: true },
		component: () => import('@/modules/base/pages/NotFound.vue')
	},
	{
		name: 'test',
		path: '/test',
		meta: { layout: 'none' },
		component: () => import('@/components/Debug/TestPage.vue'),
		children: [
			{
				name: 'test.colors',
				path: 'colors',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/ColorSheet.vue')
			},
			{
				name: 'test.dates',
				path: 'dates',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/DateSheet.vue')
			},
			{
				name: 'test.errors',
				path: 'errors',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/ErrorSheet.vue')
			},
			{
				name: 'test.form',
				path: 'form',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/FormSheet.vue')
			},
			{
				name: 'test.formfields',
				path: 'formfields',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/FormFieldsSheet.vue')
			},
			{
				name: 'test.buttons',
				path: 'buttons',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/ButtonSheet.vue')
			},
			{
				name: 'test.typography',
				path: 'typography',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/TypographySheet.vue')
			},
			{
				name: 'test.tooltips',
				path: 'tooltips',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/TooltipSheet.vue')
			},
			{
				name: 'test.editor',
				path: 'editor',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/EditorSheet.vue')
			},
			{
				name: 'test.canvas',
				path: 'canvas',
				meta: { layout: 'none' },
				component: () => import('@/components/Debug/CanvasSheet.vue')
			}
		]
	}
]

export default baseRoutes
