import doNothing from '@/helpers/api/doNothing.js'
import Logger from '@/helpers/logging/Logger.js'
import { config, pushModal } from 'jenesius-vue-modal'

config({
	scrollLock: true,
	animation: 'modal-list',
	backgroundClose: true,
	escClose: false
})

/**
 * @param {any} [component]
 * @param {object} [props]
 * @param {object} [options]
 * @param {boolean} [options.backgroundClose]
 * @param {boolean} [options.escClose]
 * @returns {Promise} an async modal
 */
export async function showModal(
	component = null,
	props = {},
	{ backgroundClose= false, escClose= false } = {}
) {
	// const close = () => popModal()
	const modal = await pushModal(component, { onClose: () => modal.close(), ...props }).catch(doNothing)

	Logger.debug(`Open modal ${modal.id}`)
	modal.onclose = (event) => {
		let canClose = true
		if (event.esc) { canClose = escClose }
		if (event.background) { canClose = backgroundClose }
		Logger.debug(`trying to close modal ${modal.id}`, { canClose })
		return canClose
	}
	return modal
}

export default showModal
